<template>

    <b-row v-if="loading" style="margin-bottom:15px"> 
    
      <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Últimas transacciones
            </h4>
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="20px"></b-skeleton>
    
    </b-col>
    
    
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
        <b-skeleton width="100%"  height="30px"></b-skeleton>
    
        </b-col>
    
        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
    
    
    
    
                      <b-skeleton-table
                  :rows="2"
                  :columns="columnas"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
    <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
     </b-row>
    
     <b-row v-else-if="code200">
      <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    
      <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
            <feather-icon
                      icon="ListIcon"
                      size="21"
                      class="color_icon"
                      /> Últimas transacciones
              </h4>
    
      </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
    
    
    <p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                <feather-icon
                                                                icon="RefreshCcwIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                />  </p>
    
    
    
    </b-col>
    
    
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px; margin-top:15px">
    
      <table class="table tablesorter"  id="datatable" >
                                  <thead class="thead-light">
                                    <tr>
                                      <th data-priority="1">Fecha/Hora</th>
                                       <th data-priority="2">Usuario</th>
                                       <th>Origen</th>
                                        <th data-priority="3">Monto</th>
                                     
                                    </tr>
                                  </thead>
                                  <tbody>
    
    
                                    <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">
    
                                     <td> {{ transaccion.dateFormated }}</td>
    
                             
                                     <td> {{ transaccion.displayNameSendMoney }}</td>
                                     <td> {{ transaccion.source }}</td>
    
                        
    
                                           <td>

                                            <p v-if="transaccion.type === 'Receive Money'"  style="text-align:center" class="entrada_money">

                                                                        
                                                          ${{transaccion.mount}} 

                                                        </p>


                                                        <p v-else class="salida_money" style="text-align:center">


                                                           ${{transaccion.mount}} 

                                                        </p>
                                            
                                           
                                            </td>
                                   
                                       
    
                              
    
                                    </tr>
                                  </tbody>
                                </table>
    
    
     </b-col>
    
    
     
    
     </b-row>
    
     <b-row v-else-if="empty">
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Últimas transacciones
            </h4>
    
    </b-col>
    
     <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
              <svg width="100" height="100" class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                      
    
                             <p class="errorTexto">
                            No se encontraron transacciones
                          </p>
    
                  </b-col>
    
     <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                               <b-button   variant="primary"  @click="refresh()" > 
                                             <feather-icon
                                              icon="RefreshCcwIcon"
                                              size="16"
                                             
                                              style="margin-right:5px; color:#fff"
                                              /> Recargar listado </b-button>
    
    
     </b-col>
    </b-row>
    
   
    
    </template>
    
    <script>
    import {
      BButton, BRow, BCol,BSkeletonTable, BSkeleton
    } from 'bootstrap-vue'
    import "jquery/dist/jquery.min.js";
    
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
    
    export default {
      components: {
        BButton,
        BSkeleton,
        BSkeletonTable,
        BRow,
        BCol,
      },
      props: ['userId','tokenAuth', 'idLocal'],
      data() {
    
        const mq = window.matchMedia( "(min-width: 990px)" );
            let columnas=6;
    
            if (!mq.matches) {
    
              columnas =3;
              }
    
        return {
          code200: false,
          loading: true,
           empty:false,
           columnas:columnas,
           transactions:[],
    
        }
      },
      computed: {
    
      },
      watch: {
    
      },
      mounted() {
    
        this.loadTable();
    
    
    
    
    
      },
      methods: {
    
        refresh() {
          this.$eventBus.$emit('reiniciarListadoTransaccionesNegocio')
        }, loadTable(){
          
            const data_json = { userId: this.userId, idLocal: this.idLocal }
                        const data_string = JSON.stringify(data_json)


                      
                        const dataEncripted = this.$encryptBackoffice.encrypt(data_string);
    
          this.$https.post('/locals/getTransactions/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
             this.loading=false;

       
         
                          if(response.data["code"] == 200){
                          
                         
                    
                           
    
                            this.transactions= response.data["transactions"];
     
                     
                                      setTimeout(() => {
                                        $("#datatable").DataTable({
                                          responsive: true,
                                          "ordering": false,
                                          lengthMenu: [
                                            [3,10, 25, 50, -1],
                                            [3,10, 25, 50, "All"],
                                          ],
                                          pageLength: 3, 
                                             "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                                        });
                                      });
    
                                         this.code200=true;
    
                         
      
                              
                            }else{
    
               
                                
    
    
                                      if (response.data.code == 401) {
    
                                        this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })
    
    
                                        
                                        localStorage.removeItem('userData')
    
    
    
                                        
                                        
    
               
    
                                        
                                        
    
                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      } else {
    
                                         if (response.data.code == 404) {
    
                                          this.$eventBus.$emit('activeHome');
                         this.$eventBus.$emit('reiniciarAllNegocio');
    
                                        
    
                                        }else{

                                            if (response.data.code == 405) {
                                                this.empty=true;
                                            }else{
                                                this.loadTable(); 
                                            }

                                            
    
                                        }
                                      
    
    
                                      }
    
                            }
                     }).catch((error) => {
    
                              this.loadTable();
    
                         
                     });
        }
    
    
      },
    }
    </script>
    
    <style lang="scss">
    
    
    </style>