<template>
<b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

  <b-row>

    
<b-col cols="12" sm="12" md="12" lg="12" xl="3" >

</b-col>

  <b-col cols="12" sm="12" md="12" lg="12" xl="6" style="padding:0px">


    
<b-modal
v-if="!isMobile"
ref="modalStep"
              centered
              hide-footer
              hideHeaderClose
              hideHeader
              size="sm"
              @hidden="closeModalStep"
    >
      <div class="d-block text-center">

        <b-row>
          <b-col cols="12" sm="12" md="12" xl="12" lg="12">

              <b-avatar
                              size="70"
                              variant="light-primary"
                              >

                              <span class="d-flex align-items-center">
                              
                                <p class="circulo_pasos">{{ steps }}/{{ maxStep }}</p>

                              </span>

                </b-avatar>

              </b-col>


              <b-col cols="12" sm="12" md="12" xl="12" lg="12">

              <p style="font-size:18px; text-align: center;margin-bottom:5px; margin-top:10px">Complete la configuración</p>
              <p style="font-size:12px; text-align: center;margin-bottom:10px; margin-top:0px">{{ steps }} de {{ maxStep }} pasos completados</p>

              <p style="font-size:14px; text-align: center;margin-bottom:15px; margin-top:0px">Haga que su negocio tome más relevancia con clientes.</p>



              </b-col>
              <step v-if="showSteps" :has-phone="hasPhone" :has-banner="hasBanner" :has-photo="hasPhoto" :has-identificacion="hasIdentificacion"  />

        </b-row>
     
      
      </div>

    </b-modal>


    <vue-bottom-dialog v-if="dialogSteps" :indentColor="indentColor" v-model="dialogSteps" :height="450" :topRadius="radius"  :overlayColor="overlay">
                  <b-row>

                    <b-col cols="12" sm="12" md="12" xl="12" lg="12" align="center">

                      <b-avatar
                                      size="70"
                                      variant="light-primary"
                                      >

                                      <span class="d-flex align-items-center">
                                      
                                        <p class="circulo_pasos">{{ steps }}/{{ maxStep }}</p>

                                      </span>

                        </b-avatar>

                      </b-col>


                      <b-col cols="12" sm="12" md="12" xl="12" lg="12">

                      <p style="font-size:18px; text-align: center;margin-bottom:5px; margin-top:10px">Complete la configuración</p>
                      <p style="font-size:12px; text-align: center;margin-bottom:10px; margin-top:0px">{{ steps }} de {{ maxStep }} pasos completados</p>

                      <p style="font-size:14px; text-align: center;margin-bottom:15px; margin-top:0px">Haga que su negocio tome más relevancia con clientes.</p>



                      </b-col>

                      <step v-if="showSteps" :has-phone="hasPhone" :has-banner="hasBanner" :has-photo="hasPhoto" :has-identificacion="hasIdentificacion"  />

                    </b-row>    
              </vue-bottom-dialog>
   
          
          <b-card>
  
                  <b-row>
                    <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" style="display:flex">

                      <div style="margin:auto">

                        
                      <b-skeleton v-if="loading" width="70px" height="70px" type="avatar" style="margin-bottom:0px !important" ></b-skeleton>
                        <vue-apex-charts
                        v-else
                    type="radialBar"
                    height="100"
                          width="100"
                    :options="goalOverviewRadialBar"
                    :series="series"
                  />

                      </div>
  
                  
  
  
                      </b-col>
  
                      <b-col cols="8" sm="8" md="8" lg="8" xl="8"  >

                      
                          <p class="p1_opciones" style="text-align: center;font-size:16px">Configure su negocio</p>  
                          <b-skeleton v-if="loading" width="100%" height="20px" style="margin-bottom:0px !important" ></b-skeleton>
                          <p v-else class="p2_opciones" style="text-align: center;font-size:12px">{{ this.steps }} de {{ this.maxStep }} pasos completados </p>  

                          <b-skeleton v-if="loading" width="100%" height="40px" style="margin-bottom:0px !important; margin-top:12px;" ></b-skeleton>
                       
                       <b-button v-else
                                                                             @click="openModal"
                                                                             variant="outline-secondary"
                                                                             size="md"
                                                                             block
                                                                             style="margin-top:12px; margin-bottom:0px"
                                                                            
                                                                           >
                                                                           <feather-icon
                 
                                                                             icon="SettingsIcon"
                                                                             size="14"
                                                                             style= "margin-right:2px"
                                                                             />
                                                                     
                                                                             Configurar negocio
                 
                                                                           </b-button>
  
                      
                      </b-col>
                  </b-row>
  
                  </b-card>
        
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="12" xl="3" >

</b-col>

  </b-row>



  </b-col>

      
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard, BAvatar, BSkeleton
      } from 'bootstrap-vue'
      import VueApexCharts from 'vue-apexcharts'
      import Step from './Step.vue'

      export default {
        components: {
            BButton,
            BAvatar,
            Step,
        BRow,
        BCol,
        BCard,
        BSkeleton,
        VueApexCharts
        },
        directives: {
        
        },
        props: ['userId', 'tokenAuth', 'idLocal'],
        data() {
    
          const $strokeColor = '#f0f2f5'
        const $textHeadingColor = '#00838c'
        const $goalStrokeColor2 = '#00838c'
    
          return {
            color: "#242526",
      indentColor:"#b0b3b8",
      overlay:"#000000e6",
      radius:"10px",
            dialogSteps:false,
            hasBanner:false,
            body: document.getElementsByTagName('body')[0],
            hasPhoto:false,
            hasIdentificacion:false,
            maxStep:0,
            isMobile: this.$isMobile,
            steps:0,
            showSteps:false,
            loading:true,
            hasPhone:false,
            series:[],
      goalOverviewRadialBar: {
        chart: {
          height: 300,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: false,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '50%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '70%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '14px',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ["#00838c"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 0,
          },
        },
      },
            
          }
        },
        computed: {
      
        },
        watch: {

          dialogSteps(newValue) {

              

            if(newValue){
              this.body.classList.add("overflow-hidden");
            }else{
              this.body.classList.remove("overflow-hidden");
            }



            },
      
      
        },
        mounted() {
    
          this.$eventBus.$on('closeModalStep1', () => {
            this.showSteps=false;

            if(this.isMobile){
              this.dialogSteps=false;
            }else{
              this.$refs.modalStep.hide();
            }

        });




        this.loadPerfil();
          
        },
        methods: {

          closeModalStep(){

            this.showSteps=false;

          },

          loadPerfil(){

            

            const data_json = { userId: this.userId, id: this.idLocal }
                const data_string = JSON.stringify(data_json)

  
                const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

              
                    this.$https.post('/locals/getPerfilComplete/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {


                    
                    if (response.data.code == 200) {

                      if(response.data.isLocalNotComplete){

                        this.$eventBus.$emit('reiniciarPerfilCompletado2', true)

                      }else{

                        this.loading = false;

                      this.steps= response.data.step;
                      this.maxStep= response.data.maxStep;
                      this.series=[response.data.stepFormated]

                      this.hasPhone=response.data.hasPhone;
                      this.hasBanner=response.data.hasBanner;
                      this.hasPhoto=response.data.hasPhoto;
                      this.hasIdentificacion=response.data.hasIdentificacion;

                      }
                

                    } else {

             


                    if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.loadPerfil();
                    }
                    }
                    }).catch(error => {
                    this.loadPerfil();
                    })

          },
      
            openModal(){

              if(this.loading){
                this.$toast.error("Espere un momento", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

              }else{
                this.showSteps=true;

                if(this.isMobile){
                  this.dialogSteps=true;

                }else{

                  this.$refs.modalStep.show();
  

                }
               
              }
    

    
    
    
    },
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
    
    
      </style>
      