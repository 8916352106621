<template>
<b-row>

  <b-col cols="12" sm="12" md="4" lg="4" xl="4" >
    <saldo-negocio  :key="componentKeySaldoNegocio" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />


  </b-col>



 
      
          <perfil-completado v-if="!isLocalPerfilComplete" :key="componentKeyPerfilCompletado" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />


          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

            <b-card>
              <transacciones :key="componentKeyTransacciones" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />

            </b-card>

    
          </b-col>


 
  

        </b-row>


   

</template>

<script>
import {
  BButton, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BSpinner, BAlert, BRow, VBPopover, BCol, BCard,
} from 'bootstrap-vue'


import SaldoNegocio from './SaldoNegocio.vue'
import PerfilCompletado from './PerfilCompletado.vue'
import Transacciones from './Transacciones.vue'

export default {
  components: {
    BAlert,
    BFormInput,
    BButton,
    PerfilCompletado,
    SaldoNegocio,
    BInputGroup,
    BInputGroupPrepend,
    Transacciones,
    BInputGroupAppend,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth', 'idLocal', 'isLocalNotComplete'],
  data() {


    return {
      componentKeyPlan:0,
      componentKeyTransacciones:0,
      componentKeySaldoNegocio:0,
      componentKeyLimites:0,
      componentKeyPerfilCompletado:0,
      stagePadding:10,
      margin:5,
      isMobile:this.$isMobile,
      isLocalPerfilComplete:this.isLocalNotComplete
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    this.$eventBus.$on('reiniciarListadoTransaccionesNegocio', () => {
      this.componentKeyTransacciones += 1
    })

    this.$eventBus.$on('reiniciarSaldoNegocio', () => {
      this.componentKeySaldoNegocio += 1

    });

    this.$eventBus.$on('reiniciarPerfilCompletado', () => {
      this.componentKeyPerfilCompletado += 1
  

    });


    this.$eventBus.$on('reiniciarPerfilCompletado2', (isLocalNotComplete) => {
      this.isLocalPerfilComplete=isLocalNotComplete;

    });

    

  },
  methods: {

   

  },
}
</script>

<style lang="scss">



</style>
