<template>

    <b-card style="margin-bottom: 10px;">



      <b-row>

        <p v-if="!loading" style="    cursor: pointer; margin: 0px; position: absolute; right: 15px; z-index: 1;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            />  </p>


      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="height: 60px; ">
      
          <p style="margin-bottom:10px; font-size:12px;text-align: center; ">Ganancia mensual</p>
                      
                      <b-skeleton v-if="loading" width="100%" height="38px" style="margin-bottom:0px !important" ></b-skeleton>
                       
                      <p v-else style=" margin-top: 0px;font-size:25px;font-weight: bold;text-align: center;" ><small style="font-size:16px">$</small>{{ balance }}<small style="font-size: 9px;">USD</small></p>
               
       
   
      </b-col>

    
    </b-row>
                


             
  
                 
  
                  </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, VBPopover, BCol, BCard
      } from 'bootstrap-vue'
      
      import ModalRetirar from './ModalRetirar.vue'

      
      export default {
        components: {
            BSkeleton,
          BButton,
          ModalRetirar,
          BRow,
          BCol,
          BCard,
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'idLocal'],
        data() {
      
      
          return {
            loading:true,
            balance:"0.00",
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.lastMonthIngresos();



            
      
        },
        methods: {

          refresh(){
            this.$eventBus.$emit('reiniciarSaldoNegocio')
          },

          lastMonthIngresos(){
              
                const data_json = { userId: this.userId, id: this.idLocal }
                const data_string = JSON.stringify(data_json)

  
                const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

              
                    this.$https.post('/locals/lastMonthIngresos/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                 

                    if (response.data.code == 200) {
                    this.loading = false;

                    this.balance= response.data.balance;

                    } else {

                    this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })


                    if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.lastMonthIngresos();
                    }
                    }
                    }).catch(error => {
                    this.lastMonthIngresos();
                    })
                    },
  
    
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      