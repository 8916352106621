<template>

    
<b-col cols="12" sm="12" md="12" xl="12" lg="12" class="scroll_div_perfil">


  <b-card v-if="!hasIdentificacion" class="card_opciones"  @click="closeModalStep('Identificacion')" block  >
    
    <b-row>
    

        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

            <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Asociar documento de identidad</p>
            <p style="text-align:left; margin:0px; font-size:12px">Es necesario para verificar su negocio </p>


        
        </b-col>

        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

            <div style="margin:auto">

              <feather-icon
                                          icon="XCircleIcon"
                                          size="20"
                                          style="color:#ff2828"
                                          />

            
            </div>



            </b-col>

        
    </b-row>

    </b-card>


    <b-card v-else class="card_step">

      <b-row>


          <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                  <p style="text-align:left; margin:0px">Documento de identidad del negocio registrada</p>

          

              
              </b-col>

              <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >

                    <feather-icon
                          icon="CheckCircleIcon"
                          size="20"
                          style="color:#00970f"
                          />
                


                  </b-col>

              
          </b-row>

      </b-card>







    
  <b-card v-if="!hasPhoto" class="card_opciones" @click="closeModalStep('Photo')" block  >
    
    <b-row>
    

        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

            <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Actualizar foto de perfil del negocio</p>
            <p style="text-align:left; margin:0px; font-size:12px">Dará una buena impresión a tus clientes </p>


        
        </b-col>

        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

            <div style="margin:auto">

              <feather-icon
                                          icon="XCircleIcon"
                                          size="20"
                                          style="color:#ff2828"
                                          />

            
            </div>



            </b-col>

        
    </b-row>

    </b-card>


    <b-card v-else class="card_step">

      <b-row>


          <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                  <p style="text-align:left; margin:0px">Foto de perfil del negocio cargada</p>

          

              
              </b-col>

              <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >

                    <feather-icon
                          icon="CheckCircleIcon"
                          size="20"
                          style="color:#00970f"
                          />
                


                  </b-col>

              
          </b-row>

      </b-card>
 
      <b-card v-if="!hasBanner" class="card_opciones" @click="closeModalStep('Banner')" block  >
    
    <b-row>
    

        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

            <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Actualizar banner del negocio</p>
            <p style="text-align:left; margin:0px; font-size:12px">Tus links de pagos quedarán más profesionales. </p>


        
        </b-col>

        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

            <div style="margin:auto">

              <feather-icon
                                          icon="XCircleIcon"
                                          size="20"
                                          style="color:#ff2828"
                                          />

            
            </div>



            </b-col>

        
    </b-row>

    </b-card>


    <b-card v-else class="card_step">

      <b-row>


          <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                  <p style="text-align:left; margin:0px">Banner de negocio cargada</p>

          

              
              </b-col>

              <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >

                    <feather-icon
                          icon="CheckCircleIcon"
                          size="20"
                          style="color:#00970f"
                          />
                


                  </b-col>

              
          </b-row>

      </b-card>

        <b-card v-if="!hasPhone" class="card_opciones" @click="closeModalStep('Whatsapp')" block  >
    
    <b-row>
    

        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

            <p style="text-align:left;margin-bottom:5px; font-weight: bold;">Registrar un número de Whatsapp</p>
            <p style="text-align:left; margin:0px; font-size:12px">No pierda comunicación con sus clientes </p>


        
        </b-col>

        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

            <div style="margin:auto">

              <feather-icon
                                          icon="XCircleIcon"
                                          size="20"
                                          style="color:#ff2828"
                                          />

            
            </div>



            </b-col>

        
    </b-row>

    </b-card>



       <b-card v-else class="card_step">

        <b-row>
    

            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                    <p style="text-align:left; margin:0px">Notificaciones por Whatsapp activado</p>

            

                
                </b-col>

                <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" >

                      <feather-icon
                            icon="CheckCircleIcon"
                            size="20"
                            style="color:#00970f"
                            />
                  


                    </b-col>

                
            </b-row>

       </b-card>



</b-col>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard
      } from 'bootstrap-vue'
      
      
      
      export default {
        components: {
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['hasPhone', 'hasBanner','hasIdentificacion', 'hasPhoto'],
        data() {
      
      
          return {
      
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
      
        },
        methods: {
  
    
          closeModalStep(type){

         
                this.$eventBus.$emit('closeModalStep1')

                switch (type) {
                    case "Whatsapp":
                    
                    this.$eventBus.$emit('clickNotificaciones')     
                      break
                    case "Photo":
                    this.$eventBus.$emit('showModalPhoto');
      
                      break
                    case "Banner":

                    this.$eventBus.$emit('showModalBanner');
      
          
                      break

                      case "Identificacion":

                      this.$eventBus.$emit('clickGeneral')     


                      break


                  }
                  
              
            }
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      