<template>

    <div>
     
    
    
         <b-modal
          ref="modalRetirar"
          centered
          hide-footer
          @hidden="closeModal"
          :title="title"
          size="sm"
        >
          <div class="d-block text-center">
    
      
                 <b-row>
    
                       <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                              >
    
                                   <b-alert
                              variant="secondary"
                              show
                              style="margin-top:10px;margin-bottom: 10px;"
                            >
                              <div class="alert-body">
                           
                                <p  class="saldoDisponible"><span style="font-size:25px">$</span>{{balance}}<small class="currency2">USD</small></p>
                                <p style="font-size: 12px;    margin: 0;">Saldo disponible para retirar</p>
    
                              
                              </div>
                            </b-alert>
    
                            
    
                              </b-col>
    
                              <b-col
                              sm="12"
                              md="12"
                              lg="12"
                              xl="12"
                              style="margin-top: 5px;margin-bottom: 5px;"
                            >
    
                              <b-form
                                autocomplete="off"
                                @submit.prevent="onSubmit"
                              >
    
                              <b-row>
    
                                     <b-col
                                        cols="12"
                                          sm="12"
                                          md="12"
                                          lg="12"
                                          xl="12"
                                        >
    
                                        
    
                                                    <p
                                            class="textLabel"
                                            style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                                          >
                                            ¿Cuánto vas a retirar? (*):</p>
    
                                            <AutoNumericVue
                                            v-model="mount"
                                            required
                                            :readonly="isDisabled"
                                            type="text" inputmode="decimal"
                                            @blur="onBlurMount"
                                            class="autonumeric_input"
                                            :options="json_opciones"
                                            :placeholder="'$0.00'"
                                        ></AutoNumericVue>
    
                                        </b-col>
    
                                     
    
                        
    
                                           <b-col
                                                    sm="12"
                                                    md="12"
                                                    lg="12"
                                                    xl="12"
                                                  >
                                                    <p
                                                      class="textLabel"
                                                      style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
                                                    >
                                                      Ingrese su código PIN (*): <feather-icon
                                                                        icon="HelpCircleIcon"
                                                                        size="16"
                                                                          v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                        title="Código PIN de seguridad"
                                                                        style="margin-right: 5px;cursor:pointer;"
                                                                        /> </p>
    
                                                    <PincodeInput
                                                      v-model="pin"
                                                      placeholder="-"
                                                      :length="6"
                                                      :autofocus="false"
                                                      :disabled="isDisabled"
                                                      :secure="true"
                                                      required
                                                    />
    
                                                  </b-col>
    
    
    
    
                                                  <b-col
                                                  cols="12"
                                                    sm="12"
                                                    md="12"
                                                    lg="12"
                                                    xl="12"
                                                  >
    
                                                    <b-button
                                                      id="btn_transferir"
                                                      ref="btn_transferir"
                                                      type="submit"
                                                      variant="primary"
                                                      block
                                                      :disabled="isDisabled"
                                                    >
    
                                                      Retirar dinero
    
                                                    </b-button>
    
                                                  </b-col>
    
                                             
    
    
                              </b-row>
                              
                              
                              </b-form>
    
                             
    
                            </b-col>
    
    
                 </b-row>
                
            </div>
    
            </b-modal>
    
    
          </div>
    
    
    </template>
    
    <script>
    import {
      VBTooltip, BButton,VBPopover,BForm,BAlert, BRow, BCol, BCard, BSkeleton
    } from 'bootstrap-vue'
    import PincodeInput from 'vue-pincode-input'
    import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
    
    export default {
      components: {
        BButton,
        BForm,
        BAlert,
        BSkeleton,
        BRow,
        BCol,
        BCard,
        AutoNumericVue,
        PincodeInput
      },
        directives: {
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
      props: ['userId','tokenAuth','idLocal', 'balance'],
      data() {
    
    
        return {
            pin:"",
            isDisabled:false,
            loading:false,
            mount:"",
            source:"",
            title:"",
            json_opciones:{},
    
    
        
        }
      },
      computed: {
    
    
    
      },
      watch: {
    
    
     
    
      },
      mounted() {
    
        this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };

        this.title="Retiro de fondos";
        this.$refs.modalRetirar.show();
    
    
    
      },
      methods: {
              closeModal(){
      
           this.$eventBus.$emit('reiniciarRetiroFondoNegocio')
    
        },
    
     
         onSubmit(event) {
          event.preventDefault()
    
         
    
            this.mount= Number(this.mount).toFixed(2);
    
    
          const userId_json = {
            userId: this.userId, pin: this.pin, mount: this.mount, idLocal:this.idLocal
          }
    
    
    
          const user_string = JSON.stringify(userId_json)
    
          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
        
          this.isDisabled = true
    
          document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Retirando'
    
          this.$https.post('/locals/withdraw/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
          this.isDisabled = false
    
        
      
            document.getElementById('btn_transferir').innerHTML = 'Retirar dinero';
           // this.$eventBus.$emit('reiniciarListadoInterbilletera');
    
            if (response.data.code == 200) {
    
               this.$refs.modalRetirar.hide();
    
            
    
              this.$toast.success(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              });
    
              this.$eventBus.$emit('reiniciarSaldoNegocio');
              this.$eventBus.$emit('reiniciarListadoTransaccionesNegocio');
    
            } else {
              this.isDisabled = false
    
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
    
              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')

                
    
                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              } else if (response.data.code == 503) {
                this.pin = ''
              }else if(response.data.code == 502){

                this.$eventBus.$emit('reiniciarSaldoNegocio');
                   
            }else if(response.data.code == 404){

              this.$eventBus.$emit('activeHome');
                         this.$eventBus.$emit('reiniciarAllNegocio');
                    
                    }
            }
          }).catch(error => {

            this.$refs.modalRetirar.hide();
    

            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
    
          
    
        
        },
        onBlurMount(){
    
    
    
          if(this.mount == null || this.mount == "null" || this.mount ==""){
            this.mount ="";
    
    
    
            }else{
    
              if(Number(this.mount) < Number("1")){
    
                 this.mount ="";
                      this.$toast.error("La cantidad a retirar debe ser mayor a la cantidad mínima de retiro de $1.00 USD" , {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })
    
    
    
              }else{
    
            
    
                  
                if(Number(this.mount) > Number(this.balance)){
                  
    
                  this.mount="";
    
                   this.$toast.error("La cantidad a retirar debe ser menor o igual a su saldo disponible de $" +this.balance+" USD" , {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
    
          
    
    
              }
    
                
    
    
    
    
              }
    
           
    
              
    
    
            }
    
        },
    
        
    
       
      }
    }
    </script>
    
    <style lang="scss">
    
    
    
    
    </style>
    